import "@fontsource/rajdhani/400.css";
import "@fontsource/rajdhani/700.css";

import "modern-normalize";

import type { GatsbyBrowser } from "gatsby";

import { createGlobalStyles } from "goober/global";
import { Fragment } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const GlobalStyles = createGlobalStyles`
  * {
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button {
    color: inherit;
    cursor: pointer;
  }
`;

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (args) => {
  const { element } = args;

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lcbl7MmAAAAADP2Vz9PITYXGHPZuOKT5P89_3OE">
      {element}
    </GoogleReCaptchaProvider>
  );
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = (args) => {
  const { element } = args;

  return (
    <Fragment>
      <GlobalStyles />

      {element}
    </Fragment>
  );
};
