module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-goober/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SpeeDons 2024 - Du 29 février au 3 mars 2024","short_name":"SpeeDons 2024","start_url":"/","background_color":"#48376b","theme_color":"#bd6bb7","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"95fd9d333a302f7e03345b6a87862889"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
